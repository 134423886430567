import React from 'react'
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import localize from '@components/localize';
import Layout from '@components/Layout'
import SEO from '@components/seo';
import PageTitle from '@components/PageTitle'
import GalleryCard from '@components/GalleryCard';

export const query = graphql`
  query {
    allSanityArt(
      sort: {fields: date, order: DESC }
    ) {
      edges {
        node {
          id
          _type
          caption {
            _type
            en
            jp
          }
          date(formatString: "YYYY")
          cover {
            asset {
              fluid(maxWidth: 784) {
                ...GatsbySanityImageFluid_withWebp
              }
              _id
              _type
            }
          }
          images {
            _key
            _type
            asset {
              _id
              _type
              fluid(maxWidth: 784) {
                ...GatsbySanityImageFluid_withWebp
              }
            }
            altText
          }
          title {
            _type
            en
            jp
          }
        }
      }
    }
  }
`;

const ArtPage = ({
  data: { allSanityArt },
  pageContext: { title, description, metaImage }
}) => {

 return (
  <Layout>
    <SEO title={title} description={description} image={metaImage?.asset.url} />
    <PageTitle>
      {title}
    </PageTitle>
    <hr className="border-grey-dark" />
    <div className="bg-black pt-12 lg:pt-24 pb-16">
      <div className="wrapper">
        <div className="row">
          <div className="col-12 lg:col-10 lg:offset-1">
            <ul className="row">
              {allSanityArt.edges.map(art => {
                return (
                  <li key={art.node.id} className="col-6 md:col-4 text-white">
                    <GalleryCard data={art.node}/>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
    <hr className="border-grey-dark" />
  </Layout>
 )
}

ArtPage.propTypes = {
  data: PropTypes.shape({
    AllSanityArt: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    metaImage: PropTypes.object,
  }),
};

ArtPage.defaultProps = {
  data: {
    AllSanityArt: {},
  },
  pageContext: {
    metaImage: {},
  },
};

export default localize(ArtPage)
